import { EnvelopeAt,  GeoAlt, TelephonePlus } from "react-bootstrap-icons";

const ContactInfo =[
 

  {
   id: "1",
   title: "Address",
   icon: { iconTitle: GeoAlt },

   info: "Dutse Alhaji, FCT Abuja "
 },
 

 {
  id: "2",
  title: "Email",
  icon: { iconTitle: EnvelopeAt},

  info: "lasglowtech@gmail.com"
},

{
  id: "3",
  title: "Phone",
  icon: { iconTitle: TelephonePlus },

  info: "+234-903-182-1590"
},
]
 
export default ContactInfo;