
const Data = [
    { id: "1",
      category: "certificate",

      img: "/Images/CERT1.jpg",
    
    },
    { id: "2",
      category: "certificate",

      img: "/Images/CERT2.jpg",
    
    },
    { id: "3",
      category: "certificate",

      img: "/Images/CERT3.jpg",
    
    },
    { id: "4",
      category: "certificate",

      img: "/Images/CERT4.jpg",
    
    },
    { id: "5",
      category: "flyers",

      img: "/Images/flyer1.jpg",
    
    },
    { id: "6",
      category: "flyers",

      img: "/Images/flyer2.jpg",
    
    },
    { id: "7",
      category: "flyers",

      img: "/Images/flyer3.png",
    
    },
    
    { id: "8",
    category: "flyers",

    img: "/Images/flyer4.jpg",
  
  },{ id: "9",
  category: "flyers",

  img: "/Images/flyer5.jpg",

},
{ id: "10",
category: "flyers",

img: "/Images/flyer6.jpg",


}, 
     
{ id: "11",
category: "flyers",

img: "/Images/flyer7.jpg",


},
 { id: "12",
category: "flyers",

img: "/Images/flyer8.jpg",

}, 

{ id: "13",
category: "flyers",

img: "/Images/flyer9.jpg",

},
{ id: "14",
category: "flyers",

img: "/Images/flyer10.jpg",

},

{ id: "15",
category: "flyers",

img: "/Images/flyer11.jpg",

},

{ id: "16",
category: "flyers",

img: "/Images/flyer12.jpg",

},

{ id: "17",
category: "flyers",

img: "/Images/flyer13.jpg",

},
{ id: "18",
category: "flyers",

img: "/Images/flyer14.png",

},
{ id: "19",
category: "flyers",

img: "/Images/flyer15.png",

},

{ id: "38",
category: "flyers",

img: "/Images/flyer16.jpg",

},


{ id: "20",
      category: "certificate",

      img: "/Images/CERT5.jpg",
    
    },
    
{ id: "21",
category: "certificate",

img: "/Images/CERT6.jpg",

},

{ id: "22",
category: "Logos/brand",

img: "/Images/LOGO1.jpg",

},

{ id: "23",
category: "Logos/brand",

img: "/Images/LOGO2.jpg",

},
{ id: "24",
category: "Logos/brand",

img: "/Images/LOGO3.jpg",

},

{ id: "25",
category: "Logos/brand",

img: "/Images/LOGO4.jpg",

},
{ id: "26",
category: "Logos/brand",

img: "/Images/LOGO5.jpg",

},
{ id: "27",
category: "Logos/brand",

img: "/Images/LOGO6.png",

},
{ id: "28",
category: "Logos/brand",

img: "/Images/LOGO7.png",

},
{ id: "29",
category: "Logos/brand",

img: "/Images/LOGO8.png",

},
{ id: "30",
category: "Logos/brand",

img: "/Images/LOGO123.jpg",

},

{ id: "35",
category: "Logos/brand",

img: "/Images/MOCKUP.jpg",

},

{ id: "36",
category: "Logos/brand",

img: "/Images/MYBRAND.jpg",

},


{ id: "37",
category: "Logos/brand",

img: "/Images/CONFIDENCE.png",

},


{ id: "31",
category: "Wedding Cards",

img: "/Images/WED1.jpg",

},
{ id: "32",
category: "Wedding Cards",

img: "/Images/WED2.jpg",

},
{ id: "33",
category: "Wedding Cards",

img: "/Images/WED3.jpg",

},

{ id: "34",
category: "Wedding Cards",

img: "/Images/WED4.jpg",

},

{ id: "39",
category: "flyers",

img: "/Images/flyer341.png",

},


{ id: "40",
category: "flyers",

img: "/Images/flyer342.png",

},

{ id: "41",
category: "flyers",

img: "/Images/flyer343.png",

},


{ id: "42",
category: "flyers",

img: "/Images/flyer344.png",

},

{ id: "43",
category: "flyers",

img: "/Images/flyer345.png",

},

{ id: "44",
category: "flyers",

img: "/Images/flyer346.png",

},

{ id: "45",
category: "flyers",

img: "/Images/flyer347.png",

},

{ id: "46",
category: "flyers",

img: "/Images/flyer348.png",

},


{ id: "47",
category: "flyers",

img: "/Images/flyer349.png",

},


{ id: "48",
category: "flyers",

img: "/Images/flyer350.png",

},





  ];
  
 
export default Data;