


import { ShieldLock, CheckCircleFill, FileLock } from 'react-bootstrap-icons';
const Featureslist = [




 { id: 1,
   Num: "01",
   Header: "Site Security Assured (SSA) ",
   info1:"Your site security is safe with us, your details remain with the admin",
   icon:{iconTitle:ShieldLock},
 
  

 },


 { id: 2,
  Num: "02",
  Header: "Media Privacy ",
  info1:"Clients information is highly encrypted and accessible only by admin",
  icon:{iconTitle:FileLock},
 

},

{ id: 3,
 Num: "03",
 Header: "Trusted ",
 info1:"You can trust us with your organizations data",
 icon:{iconTitle:CheckCircleFill},
 


},


 

   ]

   export default Featureslist;