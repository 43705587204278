
const Team = [




  { id: 1,
    name: "Austinosaz",
    Title: "Web Developer",
    info:"Founder Lasglowtech team, FullStack developer skilled in front-end technologies",
    img:"/Images/Austinosaz.jpg",
    link:"https://myfolioapp.onrender.com",
    button:"VIEW PROFILE",
    

  },


    { id: 2,
      name: "Chiemezie Exclusive",
      Title: "Full-stack developer",
      info:"Isaac Chiemezie is a full-stack developer, highly knowledgeabe in  a wide range of web applications web application development.",
      img:"/Images/Isaac.jpg",
      link:"https://linkedin.com/in/onuoha-Chiemezie-b723a8150",
      button:"CONNECT",
     
    },

   { id: 3,
      name: "Seguni",
      Title: "Visual Designer",
      info:"Adebayor Segun is a visual designer, web designer and graphics designer proficient in a whole range of  design software",
      img:"/Images/Segun.jpg",
      link:"https://wa.me/",
      button:"CONNECT",
  
    
    },

    { id: 4,
      name: "James Solomon",
      Title: "Graphic/Web designer",
      info:"Solomon James is a graphic designer talk about prints and branding,.\n he is highly skilled in sofware installation.",
      img:"/Images/James.png",
      link:"https://wa.me/",
      button:"CONNECT",
  
    
    },
    
    ]

    export default Team;