


import {  CheckCircleFill} from 'react-bootstrap-icons';
const Featureslist2 = [




 { id: 1,
   
   info1:"24/7 support",
   icon:{iconTitle:CheckCircleFill},
 
  

 },


 {id:2,
  info1:"Easily accessible",
  icon:{iconTitle:CheckCircleFill},
 

},

{id:3,
 info1:"Bug free code",
 icon:{iconTitle:CheckCircleFill},
 


},


{ id: 4,

 info1:"Modern design",
 icon:{iconTitle:CheckCircleFill},
 


},





 

   ]

   export default Featureslist2;