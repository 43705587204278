const Portfolioitems = [

 {
  id: 1,
  title: "A company website",
tech: "A site created with react-bootstrap and node js",
picture:"./imperial4.jpg",
  linkinfo: "https://www.ur9group.com"
},

{
id: 2,
title: "An NGO website",
tech: "Created with react and Tailwind",
picture:"./nmi.JPG",
linkinfo: "https://nminitiative.com",
},

{
 id: 2,
 title: "Porfolio website",
 tech: "Created with bootstrap and node",
 picture:"./Elegant.jpg",
 linkinfo: "https://myfolioapp.onrender.com",
 },

 {
  id: 2,
  title: "Personal Site",
  tech: "Created with react bootstrap",
  
  linkinfo: "https://echojones.com",
  picture:"./Images/Land3.jpg",
  
  },
]

 
export default Portfolioitems;