




import { VectorPen, Brush, CodeSlash, Activity } from "react-bootstrap-icons";

const Profile = [
  {
    id: "1",
    title: "UI/UX Design",
    icon: { iconTitle: VectorPen },

    info: "Talk of designing good looking interface, fro a brief before development. We design based on your preference"
  },

  {
    id: "2",
    title: "Website Development",
    icon: { iconTitle: CodeSlash },
    info: "Our developers are highly equiped to provide uptimum, bug free codes and users friendly web pages",

  },

  {
    id: "3",
    title: "Graphic Designing",
    icon: { iconTitle: Brush },
    info: "claim your nice looking visual designs, that suit particular purpose "
  },

  {
    id: "2",
    title: "SEO/Content Writing",
    icon: { iconTitle: Activity },
    info: "Your site is Discovearble by google search engine, in just few clicks"
  }
]

export default Profile;