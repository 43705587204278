const Testifiers =[
{

 id:1,
 Text:"Have been priviledge to engage you in one of our projects, and the result was superb.",
 Name:"Nicholas A.",
 position:"CEO NMI",
 icon:"./prof2.png",
},
{

 id:2,
 Text:"Your services, has thus project us out there, keep up the good works.",
 Name:"Ur9 group",
 position:"Real estate Company",
icon:"./ur9.png"
}



]
 
export default Testifiers;