import {  Bullseye, Eye } from "react-bootstrap-icons";
const AboutInfo =[
 

  {
   id: "1",
   title: "Our Mission",
   icon: { iconTitle: Bullseye },

   info: "To build real life projects, that meets user requirement using cutting edge technology. "
 },
 

 {
  id: "1",
  title: "Our Vision",
  icon: { iconTitle: Eye },

  info: "We are enthusiastic Tech Team  seeking  to effect and promote professionals organizations, companies, businesses, agencies. We believe every business has the right to be heard."
},
]
 
export default AboutInfo;